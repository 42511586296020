<template>
    <div class="postSuccessBox">
        <Search />
        <div class="communityContent">
            <div class="nav">
                <div @click="goUstand">
                    <div class="img">
                        <img src="../../assets/images/community/logo.png" alt="" />
                    </div>
                    <p>了解企胖胖</p>
                </div>
                <div v-for="item in nav.slice(0,4)" :key="item.id" @click="navClickFun(item)">
                    <div class="img">
                        <img style="width:50px;height:50px" :src="$util.host +item.thumbnail" alt="">
                    </div>
                    <p>{{ item .name}}</p>
                </div>
                <div v-if="nav.length > 4">
                    <el-dropdown placement="bottom" trigger="click" @command="indexChange">
                        <img style="margin:17px 0 4px ;width:50px;height:50px;display:block" src="../../assets/images/serve/gd.png" alt="">
                        <span class="el-dropdown-link">
                            更多
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in nav.slice(4,100)" :key="item.id">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- <div class="noneBox" v-if="showClass">
                    <p v-for="item in 3">
                        类别{{item}}
                    </p>
                    <p>我的创作中心</p>
                </div> -->
            </div>
        </div>
        <div class="successBox">
            <div class="success">
                <p>我要发帖</p>
                <div>
                    <img src="../../assets/images/community/ft.png" alt="">
                    <span>
                        发帖成功！现在可以：<font @click="goComm">查看帖子</font>或查看<font @click="goCreation">我的创作中心</font>
                    </span>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Search from "../../components/forumSearch.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    name: "QppIndex",
    data() {
        return {
            activeName: "默认",
            active: 0,
            currentPage: 5,
            showClass: false,
            tab: ["默认", "排行", "精品"],
            nav: [],
        };
    },
    mounted() {

        this.getClass()
    },
    methods: {
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                console.log(res);
                this.nav = res.data
            })
        },
        navClickFun(item) {
            this.$router.push({ path: '/community' })
        },
        goUstand() {
            this.$router.push({ path: '/understand' })
        },
        indexChange(item) {
            this.classifyId = item.id
            this.getTopping()
            this.getyFeatured()
            this.getListing()
            this.getDefult()
            this.getBalnner()
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        changeTab(id) {
            this.active = id;
            console.log(this.active);
        },
        goComm() {
            this.$util.post('/center/create/article/create').then(res => {
                console.log(res.data);
                this.$router.push({ path: `communityDetail?id=${res.data[0].id}` });
            })

        },
        goCreation() {
            this.$router.push({ path: "/myCreation" });
        }
    },
};
</script>
<style>
.activeClass {
    color: #e40012 !important;
    border-bottom: 2px solid #e40012;
}
</style>
<style lang="scss" scoped>
.postSuccessBox {
    .successBox {
        width: 100%;
        background: #f6f6f6;

        padding-top: 25px;
        .success {
            width: 1140px;
            padding: 30px;
            margin: auto;
            min-height: 400px;
            background: #ffffff;
            p {
                color: #222222;
                font-size: 24px;
                padding-bottom: 20px;
                border-bottom: 1px solid #eeeeee;
            }
            Img {
                display: block;
                margin: auto;
                margin-top: 145px;
                margin-bottom: 28px;
            }
            span {
                display: block;
                font-size: 18px;
                font {
                    color: #e40012;
                    cursor: pointer;
                }
                text-align: center;
            }
        }
    }
    .noneBox {
        position: absolute;
        width: 98px;
        border: 1px solid #d1d1d1;
        right: -30px;
        top: 130px;
        background: #ffffff;
        z-index: 999 !important;
        padding: 20px 0;
        p {
            font-size: 14px;
            padding-bottom: 15px;
            cursor: pointer;
        }
        p:last-child {
            padding-bottom: 0;
        }
    }
    .tabContentBox {
        width: 100%;
        height: 1700px;
        background: #f6f6f6;
        .tabContent {
            width: 1200px;
            margin: auto;
            padding-top: 30px;
            .pageInput {
                display: flex;
                justify-content: center;
                padding-top: 38px;
            }
            .list {
                padding: 30px;
                height: 198px;
                background: #ffffff;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                p:nth-child(1) {
                    color: #222222;
                    font-size: 22px;
                    padding-bottom: 12px;
                }
                p:nth-child(2) {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 14px;
                    padding-bottom: 10px;
                    span {
                        color: #666666;
                        padding-right: 74px;
                    }
                    font {
                        color: #222222;
                    }
                }
                p:nth-child(3) {
                    font-size: 16px;
                    height: 84px;
                    margin-bottom: 40px;
                }
                p:nth-child(4) {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    span {
                        color: #666666;
                        padding-right: 34px;
                    }
                    font {
                        color: #222222;
                    }
                }
                .img {
                    width: 198px;
                    height: 198px;
                    margin-right: 20px;
                }
            }
        }
    }
    .communityContent {
        width: 1200px;
        margin: auto;
        .tableBox {
            margin-top: 50px;
            .tableList {
                display: flex;
                justify-content: flex-start;
                font-size: 22px;
                div {
                    margin-right: 63px;
                    cursor: pointer;
                    padding-bottom: 5px;
                    text-align: center;
                    width: 80px;
                    color: #666666;
                }
            }
        }
        .recommendBox {
            width: 1200px;
            height: 428px;
            .recommend {
                position: absolute;
                width: 100%;
                left: 0;
                height: 418px;
                background: #fff5f6;

                .fourList {
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                width: 128px;
                                height: 42px;
                                margin-bottom: 35px;
                                font-size: 16px;
                                color: #222222;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 120px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 120px;
                    }
                }

                .twoList {
                    padding: 50px 0;
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                width: 192px;
                                height: 62px;
                                margin-bottom: 45px;
                                font-size: 24px;
                                color: #222222;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 330px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 148px;
                    }
                }
            }
        }
        .bannerBox {
            margin-bottom: 50px;
            .bannerImg {
                width: 100%;
                height: 400px;
            }
        }
        .communityData {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
            .title {
                color: #222222;
                font-size: 24px;
            }
            .data {
                display: flex;
                justify-content: right;
                div {
                    font-size: 16px;
                    margin-left: 50px;
                    font {
                        color: #999999;
                    }
                    span {
                        color: #222222;
                    }
                }
            }
        }

        .img {
            height: 50px;
            cursor: pointer;
            padding-bottom: 18px;
        }

        .nav {
            padding-bottom: 50px;
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            p {
                cursor: pointer;
            }
        }
    }
}
</style>
