import { render, staticRenderFns } from "./postSuccess.vue?vue&type=template&id=19bb5ccb&scoped=true&"
import script from "./postSuccess.vue?vue&type=script&lang=js&"
export * from "./postSuccess.vue?vue&type=script&lang=js&"
import style0 from "./postSuccess.vue?vue&type=style&index=0&lang=css&"
import style1 from "./postSuccess.vue?vue&type=style&index=1&id=19bb5ccb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19bb5ccb",
  null
  
)

export default component.exports